import "core-js/modules/es6.regexp.split";
import axios from 'axios';
import { Message, Loading } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getToken, removeToken } from '@/utils/auth';

// 创建axios实例
var service = axios.create({
  baseURL: '/api/admin' || process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // 跨域请求时发送cookie
  timeout: 60000 // 请求超时
});
var loadinIn = null;
// request 拦截器
service.interceptors.request.use(function (config) {
  // Loading.service(options)
  loadinIn = Loading.service();
  // 在发送请求之前做一些事情
  if (store.getters.token) {
    // 让每个请求都携带 token
    config.headers['Authorization'] = 'Bearer ' + getToken();
  }
  return config;
}, function (error) {
  // 这里处理一些请求出错的情况
  console.log(error); // for debug
  return Promise.reject(error);
});

/**
 * response 拦截器 (可以对请求返回的数据进行相对于操作)
 *
 * 状态码: response.status_code
 * 200 请求成功
 * 201 对创建新资源的 POST 操作进行响应
 * 202 服务器接受了请求，但是还未处理，响应中应该包含相应的指示信息，告诉客户端该去哪里查询关于本次请求的信息
 * 204 对不会返回响应体的成功请求进行响应（比如 DELETE 请求）
 * 304 HTTP 缓存 header 生效的时候用
 * 400 请求异常，比如请求中的 body 无法解析
 * 401 没有进行认证或者认证非法
 * 403 服务器已经理解请求，但是拒绝执行它
 * 404 请求一个不存在的资源
 * 405 所请求的 HTTP 方法不允许当前认证用户访问
 * 410 表示当前请求的资源不再可用
 * 415 如果请求中的内容类型是错误的
 * 422 用来表示校验错误
 * 419 由于请求频次达到上限而被拒绝访问
 * 500 直接死亡
 */
service.interceptors.response.use(function (response) {
  loadinIn.close();
  if (response.request.responseType === 'blob') {
    if (response.headers['content-disposition']) {
      var filename = response.headers['content-disposition'].split("utf-8''")[1];
      return {
        filename: decodeURIComponent(filename),
        res: response.data
      };
    }
  }
  return response.data;
}, function (error) {
  loadinIn.close();
  switch (error.response.status) {
    case 400:
      Message.error({
        message: '请求异常,' + error.response.data.message
      });
      break;
    case 401:
      Message.error({
        message: '登录态失效,请重新登录'
      });
      removeToken();
      router.push({
        path: '/login'
      });
      break;
    case 403:
      Message.error({
        message: '服务器已经理解请求，但是拒绝执行它'
      });
      break;
    case 404:
      Message.error({
        message: '与服务器失去连接'
      });
      break;
    case 500:
      Message.error({
        message: '网络错误，请刷新重试',
        duration: 10000
      });
      break;
    default:
      Message.error({
        message: error.response.data.message
      });
  }
  return Promise.reject(error);
});
export default service;