module.exports = {
  /**
   * @description 标题设置
   */
  title: '监考网管理平台',
  /**
   * @description 是否修复标题
   */
  fixedHeader: false,
  /**
   * @description 是否在边栏中显示徽标
   */
  sidebarLogo: true
};