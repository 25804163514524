//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '监考平台 Beat_v2.0',
      // logo: require('@/assets/logo/logo.jpg') 没有logo暂时图展示
      logo: ''
    };
  }
};