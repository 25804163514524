import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * 注意: 子菜单仅在路由children.length> = 1时出现
 * 详细信息请参见: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   如果hidden设置为true，则项目不会显示在边栏中（默认为false）
 * alwaysShow: true               如果设置为true，则将始终显示根菜单
 *                                如果未设置alwaysShow，则当项有多个子路线时，
 *                                它将变为嵌套模式，否则不显示根菜单
 * redirect: noRedirect           如果设置了noRedirect将不会在面包屑中重定
 * name:'router-name'             该名称由<keep-alive>使用（必须设置！！！）
 * meta : {
    roles: ['admin','editor']    控制页面角色（您可以设置多个角色）
    title: 'title'               “title”名称显示在侧边栏和面包屑中（推荐设置）
    icon: 'svg-name'             'svg-name'图标显示在侧栏中
    breadcrumb: false            如果设置为false，则该项将隐藏在面包屑中（默认值为true）
    activeMenu: '/example/list'  如果设置了路径，则为“ / example / list”，边栏将突出显示您设置的路径
    childrenPage: true            是否有第三级菜单
  }
 */

/**
 * constantRoutes
 * 没有权限要求的基本页面
 * 可以访问所有角色
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/other/404.vue');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/exams',
  name: 'Exams',
  meta: {
    title: '考试管理',
    icon: 'nested',
    breadcrumb: false
  },
  children: [{
    path: 'exams',
    name: 'Exams',
    component: function component() {
      return import('@/views/exams/index');
    },
    meta: {
      title: '考试列表'
    },
    children: [{
      path: 'createdoredit',
      name: 'CreatedOrEdit',
      hidden: true,
      component: function component() {
        return import('@/views/exams/components/createdOrEdit');
      },
      meta: {
        title: '考试操作',
        childrenPage: true
      }
    }, {
      path: 'examinees',
      name: 'Examinees',
      hidden: true,
      component: function component() {
        return import('@/views/exams/components/examinees');
      },
      meta: {
        title: '考生信息',
        childrenPage: true
      }
    }, {
      path: 'invigilators',
      name: 'Invigilators',
      hidden: true,
      component: function component() {
        return import('@/views/exams/components/invigilators');
      },
      meta: {
        title: '监考审核',
        childrenPage: true
      }
    }, {
      path: 'training',
      name: 'Training',
      hidden: true,
      component: function component() {
        return import('@/views/exams/components/invigilators/components/Training.vue');
      },
      meta: {
        title: '监考培训',
        childrenPage: true
      }
    }, {
      path: 'places',
      name: 'Places',
      hidden: true,
      component: function component() {
        return import('@/views/exams/components/places');
      },
      meta: {
        title: '考场安排',
        childrenPage: true
      }
    }, {
      path: 'messages',
      name: 'Messages',
      hidden: true,
      component: function component() {
        return import('@/views/exams/components/messages');
      },
      meta: {
        title: '消息记录',
        childrenPage: true
      }
    }]
  }]
}, {
  path: '/places',
  component: Layout,
  name: 'Places',
  meta: {
    title: '考点管理',
    icon: 'example'
  },
  children: [{
    path: '',
    name: 'Places',
    component: function component() {
      return import('@/views/places/index');
    },
    meta: {
      title: '考点管理',
      childrenPage: true
    }
  }, {
    path: 'createPlaces',
    name: 'createPlaces',
    hidden: true,
    component: function component() {
      return import('@/views/places/components/createPlaces');
    },
    meta: {
      title: '新增考点 / 楼栋',
      childrenPage: true
    }
  }, {
    path: 'editPlaces',
    name: 'editPlaces',
    hidden: true,
    component: function component() {
      return import('@/views/places/components/editPlaces');
    },
    meta: {
      title: '考点编辑',
      childrenPage: true
    }
  }]
}, {
  path: '/invigilators',
  component: Layout,
  name: 'Invigilators',
  children: [{
    path: 'invigilators',
    name: 'Invigilators',
    component: function component() {
      return import('@/views/invigilators/index');
    },
    meta: {
      title: '监考管理',
      icon: 'eye-open'
    }
  }]
}, {
  path: '/settleMoney',
  component: Layout,
  name: 'SettleMoney',
  meta: {
    title: '结算管理',
    icon: 'password',
    breadcrumb: false
  },
  children: [{
    path: 'settleMoney',
    name: 'SettleMoney',
    component: function component() {
      return import('@/views/settleMoney/index');
    },
    meta: {
      title: '结算名单'
    }
  }, {
    path: 'recordMoney',
    name: 'recordMoney',
    component: function component() {
      return import('@/views/recordMoney/index');
    },
    meta: {
      title: '结算记录'
    }
  }]
}, {
  path: '/template',
  component: Layout,
  redirect: 'training',
  alwaysShow: true,
  name: 'Template',
  meta: {
    title: '模板管理',
    icon: 'nested',
    breadcrumb: false
  },
  children: [{
    path: 'training',
    name: 'Training',
    component: function component() {
      return import('@/views/template/training/index');
    },
    meta: {
      title: '培训事项'
    }
  }, {
    path: 'placeInformation',
    name: 'PlaceInformation',
    component: function component() {
      return import('@/views/template/placeInformation/index');
    },
    meta: {
      title: '考点资料'
    }
  }, {
    path: 'questionbank',
    name: 'QuesttionBank',
    component: function component() {
      return import('@/views/template/questionBank/index');
    },
    meta: {
      title: '考试题库'
    }
  }]
}, {
  path: '/permissions',
  component: Layout,
  name: 'Permissions',
  children: [{
    path: 'permissions',
    name: 'Permissions',
    component: function component() {
      return import('@/views/permissions/index');
    },
    meta: {
      title: '权限管理',
      icon: 'user'
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;