import Cookies from 'js-cookie';
import api from '@/api';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  textSite: null,
  config: null
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  TEXT_SITE: function TEXT_SITE(state, data) {
    state.textSite = data;
  },
  CONFIG: function CONFIG(state, data) {
    state.config = data;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  // 获取考点列表.
  TextSite: function TextSite(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve, reject) {
      var resources = [{
        name: 'schoollist'
      }];
      api.restful({
        resources: resources
      }).then(function (res) {
        commit('TEXT_SITE', res.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取配置
  Config: function Config(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      var resources = [{
        name: 'config'
      }];
      api.restful({
        resources: resources
      }).then(function (res) {
        commit('CONFIG', res.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};