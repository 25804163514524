import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import request from '@/utils/request';
export default {
  // 登录
  login: function login(data) {
    return request({
      url: '/login',
      method: 'post',
      data: data
    });
  },
  // 退出登录
  logout: function logout() {
    return request({
      url: '/logout',
      method: 'post'
    });
  },
  // 获取用户信息
  getInfo: function getInfo(params) {
    return request({
      url: '/info',
      method: 'get',
      params: params
    });
  },
  // 导出考试监考人员
  Export: function Export(params, url) {
    return request({
      url: url,
      method: 'get',
      params: params,
      responseType: 'blob'
    });
  },
  /** restful模块入参说明：
   * resources {array} 资源名字数组，name为资源名，id为资源id(可选)
   * method {string} 方法名，默认为get
   * params {object}
   * data {object}
   * loading {boolean} 是否显示加载动画
   */
  restful: function restful(_ref) {
    var resources = _ref.resources,
      _ref$method = _ref.method,
      method = _ref$method === void 0 ? 'get' : _ref$method,
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$data = _ref.data,
      data = _ref$data === void 0 ? {} : _ref$data,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? true : _ref$loading,
      _ref$url = _ref.url,
      url = _ref$url === void 0 ? '' : _ref$url;
    resources.forEach(function (item) {
      url += "/".concat(item.name).concat(item.id ? '/' + item.id : ''); // 判断是否有资源id，若有，则拼上资源id，若无，则直接为资源名称
    });
    return request({
      url: url,
      method: method,
      params: params,
      data: data,
      loading: loading
    });
  }
};