import "core-js/modules/es6.function.name";
import api from '@/api';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  roles: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  }
};
var actions = {
  // 用户登录
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password,
      usertype = userInfo.usertype;
    return new Promise(function (resolve, reject) {
      api.login({
        username: username.trim(),
        password: password,
        usertype: usertype
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.access_token);
        setToken(data.access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 用户 退出登录
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      api.logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        removeToken();
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      api.getInfo(state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var name = data.name,
          avatar = data.avatar;
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};