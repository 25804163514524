import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PnTable',
  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        vm: Object,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: function render(h, ctx) {
        var params = {
          row: ctx.props.row,
          index: ctx.props.index
        };
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render({
          h: h,
          params: params,
          vm: ctx.parent
        });
      }
    }
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      } // prop:表头绑定的地段，label：表头名称，align：每列数据展示形式（left, center, right），width:列宽
    },
    // 数据列表
    columns: {
      type: Array,
      default: function _default() {
        return [];
      } // 需要展示的列 === prop：列数据对应的属性，label：列名，align：对齐方式，width：列宽
    },
    operates: {
      type: Object,
      default: function _default() {
        return {
          list: []
        };
      } // width:按钮列宽，fixed：是否固定（left,right）,按钮集合 === label: 文本，type :类型（primary / success / warning / danger / info / text），show：是否显示，icon：按钮图标，plain：是否朴素按钮，disabled：是否禁用，method：回调方法
    },
    total: {
      type: Number,
      default: 0
    },
    // 总数
    pagination: {
      type: Object,
      default: null // 分页参数 === per_page:每页展示的条数，pageIndex:当前页，pageArray: 每页展示条数的控制集合，默认 _page_array
    },
    border: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: null
    },
    maxHeight: {
      type: String,
      default: null
    },
    // 计算表格的高度
    options: {
      type: Object,
      default: function _default() {
        return {
          stripe: false,
          // 是否为斑马纹 table
          loading: false,
          // 是否添加表格loading加载动画
          highlightCurrentRow: false,
          // 是否支持当前行高亮显示
          mutiSelect: false // 是否支持列表项选中功能
        };
      }
    },
    // table 表格的控制参数
    tableRowClassName: {
      type: Function,
      default: function _default() {
        return function () {
          return '';
        };
      }
    }
  },
  data: function data() {
    return {
      pageIndex: 1,
      tableCurrentPagination: {},
      multipleSelection: [] // 多行选中
    };
  },
  watch: {
    pagination: function pagination(val) {
      this.tableCurrentPagination = val || {
        per_page: this.total,
        pageIndex: 1
      }; // 判断是否需要分页
    }
  },
  mounted: function mounted() {
    this.tableCurrentPagination = this.pagination || {
      per_page: this.total,
      pageIndex: 1
    }; // 判断是否需要分页
  },
  methods: {
    // 切换每页显示的数量
    handleSizeChange: function handleSizeChange(size) {
      if (this.pagination) {
        this.tableCurrentPagination = {
          pageIndex: 1,
          per_page: size,
          pageArray: this.tableCurrentPagination.pageArray
        };
        this.$emit('handleSizeChange', this.tableCurrentPagination);
      }
    },
    // 切换页码
    handleIndexChange: function handleIndexChange(currnet) {
      if (this.pagination) {
        this.tableCurrentPagination.pageIndex = currnet;
        this.$emit('handleIndexChange', this.tableCurrentPagination);
      }
    },
    // 多行选中
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('handleSelectionChange', val);
    },
    // 判断是否显示按钮
    showButtonFn: function showButtonFn(btn, index, row) {
      if (btn.show) {
        if (typeof btn.show === 'function') {
          return btn.show({
            index: index,
            row: row
          });
        } else {
          return btn.show;
        }
      }
      return true;
    },
    // 筛选
    handleFilter: function handleFilter(value, row, column) {
      var property = column['property'];
      return row[property] === value;
    },
    // 操作列,自定义事件
    handleCustomize: function handleCustomize(val) {
      this.$emit('inc', val);
    }
  }
};